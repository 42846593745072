
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  nextTick,
  // watchEffect,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import { scrollIntoView } from '@/use/useScroll';
import useBreakpoints from '@/use/useBreakpoints';

import Loader from '@/components/Loader/Loader.vue';
import Icon from '@/components/Icon/Icon.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import ItemStack from '@/components/ItemStack/ItemStack.vue';
import { HelpTopics } from '@/components/ItemStack/ItemStack.types';

import { useAmbassador } from '@/modules/academy/composables/ilumaJourney';
import { useHomepage } from '@/modules/academy/composables/homepage';
import { useAcademyData } from '@/modules/academy/composables/academyData';

import AcademyCard from '@/modules/academy/components/AcademyCard/AcademyCard.vue';
import AcademyCampaignCard from '@/modules/academy/components/AcademyCampaignCard/AcademyCampaignCard.vue';
import IlumaAcademyCampaignCard from '@/modules/academy/components/IlumaAcademyCampaignCard/IlumaAcademyCampaignCard.vue';
import AcademyHeroTeaser from '@/modules/academy/components/AcademyHeroTeaser/AcademyHeroTeaser.vue';
import AcademyHeroMessageBlock from '@/modules/academy/components/AcademyHeroMessageBlock/AcademyHeroMessageBlock.vue';

import { Campaign, Challenge } from '@/modules/academy/types';
import { routeNames, ROOT_URL } from '@/modules/academy/router';

import { pushDataLayer } from '@/utils/GTMCustomDataLayers/academy';

const DISPLAY_CHALLENGES_LIMIT = 11;

const ACADEMY_CHALLENGE_TEASERS_POSITION = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
};

export default defineComponent({
  name: 'AcademyHome',
  components: {
    AcademyCampaignCard,
    AcademyCard,
    AcademyHeroMessageBlock,
    AcademyHeroTeaser,
    Icon,
    IlumaAcademyCampaignCard,
    ItemStack,
    Loader,
    Pagination,
  },
  setup() {
    const { query } = useRoute();
    const store = useStore();

    const isPageFilterClick = ref(false);
    const topGridRef = ref();

    const {
      ambassador,
      otherAmbassador,
      ambassadorList,
      saveAmbassador,
      shouldDisplayIlumaTeaser,
      isLoaded: ambassadorIsLoaded,
    } = useAmbassador();

    const {
      grid,
      challenges,
      firstChallenge,
      campaigns,
      ilumaChallenges,
      ilumaGroup,
      isLoaded: homepageDataIsLoaded,
    } = useHomepage();

    const { academyData, isLoaded: academyDataIsLoaded } = useAcademyData();

    const dataLoaded = computed(
      () => homepageDataIsLoaded && academyDataIsLoaded && ambassadorIsLoaded
    );

    const { isLg } = reactive(useBreakpoints());
    const isScreenLg = isLg();

    const filters = {
      alle: 'all',
      challenges: 'playable',
      // Remove podcasts temporarily
      // podcasts: 'podcast',
      videos: 'video',
    };

    const selectedFilter = computed(
      () => store.state.academyModule.homepageFilter
    );

    const selectFilter = async (value: string) => {
      isPageFilterClick.value = true;
      changePageWithoutScroll(0, DISPLAY_CHALLENGES_LIMIT);

      // Reset pagination component (so 1st page appears as selected)
      resetPaginationRef.value += 1;
      store.dispatch('academyModule/setHomepageFilter', value);

      isPageFilterClick.value = false;
    };

    onMounted(() => {
      if (!store.state.academyModule.homepageFilter) {
        store.dispatch('academyModule/setHomepageFilter', filters.alle);
        selectFilter(filters.alle);
      }
    });

    onUnmounted(() => {
      store.dispatch('academyModule/resetChallengeDone');
    });

    const challengeDone = computed(
      () => store.state.academyModule.challengeDone
    );

    const onSwitchAmbassador = () => {
      saveAmbassador(otherAmbassador.value, true);
    };

    const resetPaginationRef = ref(0);
    const startSlice = computed<number>(
      () => store.state.academyModule.homepagePagination.startSlice
    );
    const endSlice = computed<number>(
      () => store.state.academyModule.homepagePagination.endSlice
    );

    const curriedChangePage = (config = { shouldScroll: false }) => async (
      newStartSlice: number,
      newEndSliceArg: number
    ): Promise<void> => {
      const isChangingPage = startSlice.value !== newStartSlice;
      store.dispatch('academyModule/setHomepagePagination', {
        startSlice: newStartSlice,
        endSlice: newEndSliceArg,
      });

      if (isChangingPage) {
        await nextTick();
        config.shouldScroll && scrollIntoView(topGridRef.value);
      }
    };

    const changePageWithScroll = curriedChangePage({ shouldScroll: true });
    const changePageWithoutScroll = curriedChangePage({ shouldScroll: false });

    const shouldDisplayCampaignAsGridItem = computed(
      () =>
        ilumaChallenges.value.length === 2 &&
        startSlice.value === 0 &&
        selectedFilter.value === filters.alle
    );

    const filterByChallengeType = (items: Challenge[]): Challenge[] =>
      items.filter((item) =>
        [filters.alle, (item as Challenge).challengeContent?.type].includes(
          selectedFilter.value
        )
      );

    const filteredChallenges = computed(() =>
      filterByChallengeType(challenges.value)
    );

    const totalAmountOfItems = computed(
      () =>
        grid.value
          ?.slice(grid.value.length > 0 ? 1 : 0)
          .filter(
            (item) =>
              !(item as Campaign).bigHeadline &&
              (selectedFilter.value === filters.alle ||
                (item as Challenge)?.challengeContent.type ===
                  selectedFilter.value)
          ).length
    );

    const paginatedChallenges = computed(() => {
      const ilumaChallengesWithDummyItems = new Array(5)
        .fill(null)
        .map((item, index) => ilumaChallenges.value[index] || item);

      // add checking for top teaser firstChallenge type, if its type matches with filter then make offset 1, otherwise 0 not to lose it in displayed list
      const offset =
        shouldDisplayIlumaCampaignTeaser.value ||
        (selectedFilter.value !== filters.alle &&
          selectedFilter.value !== teaserProps?.value?.type)
          ? 0
          : 1;

      const correctedPaginatedChallenges = [
        ...(selectedFilter.value !== filters.alle
          ? filterByChallengeType(ilumaChallenges.value)
          : ilumaChallenges.value.length > 0
          ? ilumaChallengesWithDummyItems
          : []),
        ...filteredChallenges.value,
      ];

      const correctedSlicedPaginatedChallenges =
        correctedPaginatedChallenges.slice(
          startSlice.value + offset,
          endSlice.value + offset
        );

      return correctedSlicedPaginatedChallenges;
    });

    const paginatedChallengesTop = computed(() =>
      paginatedChallenges.value.slice(0, 4)
    );
    const paginatedChallengesBot = computed(() =>
      paginatedChallenges.value.slice(4)
    );

    const ilumaCampaignPositionIndex = computed(() => {
      const { length } = ilumaChallenges.value;
      return length > 1
        ? length - 1
        : length === 1
        ? 0
        : Math.min(4, paginatedChallenges.value.length - 1);
    });

    const campaignsWithIluma = computed(() => {
      const debug = null; // '[HARDCODED ILUMA CAMPAIGN]';
      return [
        // This hardcoded campaign needs to be here to be replaced
        // by the template, instead of teh actual 1st campaign.
        ...(ilumaChallenges.value.length > 0
          ? [
              {
                id: 0,
                title: debug,
                bigHeadline: debug,
                smallHeadline: debug,
                text: debug,
                label: debug,
                labelColor: debug,
                fontStyle: debug,
                image: {
                  alt: debug,
                  desktop: { x1: '' },
                  mobile: { x1: '' },
                },
                link: {
                  text: debug,
                  href: debug,
                },
              },
            ]
          : []),
        ...campaigns.value,
      ];
    });

    const shouldDisplayIlumaCampaignTeaser = computed(() =>
      shouldDisplayIlumaTeaser(ilumaGroup.value)
    );

    const teaserProps = computed(() =>
      shouldDisplayIlumaCampaignTeaser.value
        ? {
            title: 'Deine ILUMA Special Prämie wartet.',
            ctaName: 'Jetzt Prämie sichern',
            image: {
              alt: 'Iluma Campaign prizes',
              desktop: {
                x1: require('@/assets/academy/iluma-reward-teaser-campaign-teaser-xl@1x.jpg'),
                x2: require('@/assets/academy/iluma-reward-teaser-campaign-teaser-xl@2x.jpg'),
              },
              mobile: {
                x1: require('@/assets/academy/iluma-reward-teaser-campaign-teaser-s@1x.jpg'),
                x2: require('@/assets/academy/iluma-reward-teaser-campaign-teaser-s@2x.jpg'),
              },
            },
            link: `${ROOT_URL}/iluma-group-gift`,
            alternateBackground: true,
            ambassadorHeadline: `Super, ${store.state?.consumerModule?.consumer?.firstName}!`,
            ambassadorCopy:
              'Als Dankeschön sichere Dir eine gratis Packung TEREA*',
          }
        : !firstChallenge.value
        ? {
            category: 'Deine abgeschlossenen Inhalte',
            title: 'Frische hier Dein Wissen auf.',
            ctaName: 'Zum Archiv',
            image: {
              alt: 'Academy Archiv',
              desktop: {
                x1: require('@/assets/academy/academy-archive.png'),
                x2: require('@/assets/academy/academy-archive@2x.png'),
                x3: require('@/assets/academy/academy-archive@3x.png'),
              },
              mobile: {
                x1: require('@/assets/academy//academy-archive.png'),
                x2: require('@/assets/academy//academy-archive@2x.png'),
                x3: require('@/assets/academy//academy-archive@3x.png'),
              },
            },
            link: `${ROOT_URL}/archive`,
            alternateBackground: true,
            ambassadorHeadline: academyData?.value?.headlineEmptyHome,
            ambassadorCopy: academyData?.value?.copyEmptyHome,
          }
        : {
            title: firstChallenge?.value?.title,
            points: firstChallenge?.value?.points,
            pointsType: firstChallenge?.value?.pointsType,
            label: firstChallenge?.value?.label,
            labelColor: firstChallenge?.value?.labelColor,
            ctaName: firstChallenge?.value?.challengeContent.ctaName,
            category: firstChallenge?.value?.category,
            type: firstChallenge?.value?.challengeContent.type,
            image: firstChallenge?.value?.image,
            link: firstChallenge?.value?.link,
          }
    );

    const secondCampaignToDisplay = computed(() => {
      const paginationPage = Math.ceil(
        startSlice.value / DISPLAY_CHALLENGES_LIMIT
      );
      return campaignsWithIluma.value[paginationPage + 1];
    });

    const prepareAndSendGTMObject = (title, challengeType, position) => {
      const homeTeaserClickGTMObject = {
        event: 'teaserClick',
        elementType:
          challengeType && challengeType !== 'academy2.AcademyChallengePage'
            ? challengeType?.toLowerCase()
            : 'external_link',
        challengeName: title,
        challengeType: challengeType,
        teaserPosition: position,
      };

      pushDataLayer('teaserClick', {
        homeTeaserClick: homeTeaserClickGTMObject,
      });
    };

    const onHeroTeaserClick = () => {
      prepareAndSendGTMObject(
        teaserProps.value?.title,
        teaserProps.value?.type,
        ACADEMY_CHALLENGE_TEASERS_POSITION.TOP
      );
    };

    const onAcademyCardClick = ({ title, type, teaserPosition }) => {
      prepareAndSendGTMObject(title, type, teaserPosition);
    };

    const onContentClick = (title, teaserPosition) => {
      prepareAndSendGTMObject(title, null, teaserPosition);
    };

    const helpTopics: HelpTopics[] = [
      {
        title: 'Academy FAQs',
        icon: 'faq',
        text: 'Rund um den IQOS Club',
        href: `${ROOT_URL}/faqs`,
      },
      {
        title: 'Support & Assistance',
        icon: 'info',
        text: 'Weitere Kontaktmöglichkeiten',
        href: 'https://www.iqos.com/de/de/support.html',
      },
      {
        title: 'Community',
        icon: 'coach',
        text: 'Tipps & Tricks auf Facebook',
        href: 'https://www.facebook.com/iqos.support.de/',
      },
    ];

    const customReward = computed(() =>
      query.gift === 'product'
        ? 'Deine gratis TEREA Packung*'
        : query.gift === 'points'
        ? '+300 Statuspunkte'
        : null
    );

    const isIlumaCompletedWithProduct = computed(() =>
      ['product'].includes(query.gift as string)
    );

    const shouldDisplayIlumaChallenges = computed(
      () => ilumaChallenges.value?.length > 1
    );

    return {
      challenges,
      filteredChallenges,
      paginatedChallenges,
      shouldDisplayIlumaChallenges,
      ilumaCampaignPositionIndex,
      paginatedChallengesTop,
      paginatedChallengesBot,
      shouldDisplayCampaignAsGridItem,
      ACADEMY_CHALLENGE_TEASERS_POSITION,
      academyData,
      ambassador,
      ambassadorList,
      topGridRef,
      campaignsWithIluma,
      challengeDone,
      changePageWithScroll,
      customReward,
      dataLoaded,
      DISPLAY_CHALLENGES_LIMIT,
      filters,
      firstChallenge,
      grid,
      helpTopics,
      ilumaChallenges,
      ilumaGroup,
      isIlumaCompletedWithProduct,
      isScreenLg,
      onAcademyCardClick,
      onContentClick,
      onHeroTeaserClick,
      onSwitchAmbassador,
      otherAmbassador,
      resetPaginationRef,
      routeNames,
      secondCampaignToDisplay,
      selectedFilter,
      selectFilter,
      shouldDisplayIlumaCampaignTeaser,
      startSlice,
      teaserProps,
      userName: store.state.consumerModule.consumer.firstName,
      totalAmountOfItems,
    };
  },
});
